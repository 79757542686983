.itemBox img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto
}

.itemBox p {
    margin: 0 auto;
    margin-top: 10px
}

.itemBox a {
    color: #3B3B3B;
}

.itemBox a:hover {
    color: #91AF69;
}

.itemBox a:visited {
    color: grey
}

.itemBox a:visited:hover {
    color: #91AF69
}

.uxImg {
    height: 250px;
}

#uxHr {
    border: 1px solid #3B3B3B;
    width: 50%
}
