body {
    background-color: black;
}

h1, h2 {
    font-family: 'Cinzel', serif;
    color: white;
    text-align: center;
}

p {
    font-family: 'Baskerville';
    color: white;
}

hr {
    border: 1px solid #3B3B3B;
    width: 80%
}

.title2 {
    display: none;
}

#img2 {
    display: none;
}

form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.frenchyTitle {
    text-shadow: 5px 10px 10px rgba(0, 0, 0, 1);
    text-align: center;
    margin-bottom: -3px;
}

.heroTransparent {
    position: absolute;
    margin-top: 5%
}

.hero {
    width: 100vw;
    height: auto;
}

.hero img {
    margin-bottom: 10px
}

.frenchysFace {
    display: flex;
    justify-self: center;
    align-self: center;
    margin: 0 auto;
    border-radius: 50%;
    border: 4px solid white;
    height: 150px;
    margin-top: 5%;
}

.heroBox p {
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
    text-shadow: 10px 10px 10px rgba(0, 0, 0, 3);
    font-size: 17px;
}

#heroButtons {
    margin-top: -10px;
}

.workBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;
}

.workTitle {
    text-align: center;
}

.workItem img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto
}

.workItem p {
    margin-left: 40px;
    margin-right: 40px;
}

.homeGif {
    height: 250px;
}

.heroBox2 {
    margin-top: 50px;
}

.heroBox2 p {
    position: relative;
    bottom: 300px;
    width: 60%;
    margin: 0 auto;
    text-shadow: 5px 10px 10px rgba(0, 0, 0, 1);
}

.heroBox2 form {
    margin-top: -100px
}

.frenchyTitle2 {
    text-shadow: 5px 10px 10px rgba(0, 0, 0, 1);
    position: relative;
    bottom: 10em;
    text-align: center;
}

.readMoreButton {
    font-family: 'IM Fell Double Pica SC', serif;
    color: white;
    font-size: 15px;
    background: #34471b;
    border: none;
    border-radius: 6px;
    width: 450px;
    height: 30px;
    margin: 5px;
}

.readMoreButton:hover {
    background: #7F995D;
    color: black;
}

#uxButton {
    background: #3B3B3B;
}

#uxButton:hover {
    background: #750632;
}

.contactBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
}

a.lightbox img {
    max-height: 800px;
    margin: 20px
}

.lightbox-target {
    position: fixed;
    z-index: 10;
    top: -100%;
    width: 100%;
    background: rgba(0,0,0,.7);
    width: 100%;
    opacity: 0;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    overflow: hidden;
}

.lightbox-target img {
    margin: auto;
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom: 0;
    max-height: 0%;
    max-width: 0%;
    border: 3px solid white;
    box-shadow: 0px 0px 8px rgba(0,0,0,.3);
    box-sizing: border-box;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

a.lightbox-close {
    display: block;
    width:50px;
    height:50px;
    box-sizing: border-box;
    background: white;
    color: black;
    text-decoration: none;
    position: absolute;
    top: -80px;
    right: 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

a.lightbox-close:before {
    content: "";
    display: block;
    height: 30px;
    width: 1px;
    background: black;
    position: absolute;
    left: 26px;
    top:10px;
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    transform:rotate(45deg);
}

a.lightbox-close:after {
    content: "";
    display: block;
    height: 30px;
    width: 1px;
    background: black;
    position: absolute;
    left: 26px;
    top:10px;
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -o-transform:rotate(-45deg);
    transform:rotate(-45deg);
}

.lightbox-target:target {
    opacity: 1;
    top: 0;
    bottom: 0;
}
    
.lightbox-target:target img {
    max-height: 100%;
    max-width: 100%;
}
    
.lightbox-target:target a.lightbox-close {
    top: 0px;
}

.mailTo {
    height: 100px;
}

.emailBox {
    margin: 20px;
}

.emailBox a {
    text-decoration: none;
}

.emailBox h2:hover {
    color: #7F995D;
}

#uxButton {
    margin-top: -180px;
}

@media(min-width: 1000px) {
    .workItem {
        width: 70%;
    }

    .heroBox p {
        width: 60%;
        margin: 0 auto;
        margin-top: 30px;
    }

    .heroBox2 p {
        width: 60%;
        margin: 0 auto;
    }

    .itemBox p {
        width: 60%;
    }

    .itemBox h2 {
        margin-top: 20px;
    }
}

@media(max-width: 999px) {
    .heroBox p {
        width: 90%
    }

    .itemBox p {
        width: 70%;
    }

    .itemBox h2 {
        margin-top: 20px;
    }
}

@media(max-width: 900px) {
    .frenchysFace {
        margin-top: 10%;
    }

    .heroBox p {
        margin-top: 120px;
        width: 90%;
    }

    .workTitle {
        margin-top: 170px
    }

    #uxButton {
        margin-top: -170px;
    }
}

@media(max-width: 860px) {
    .workTitle {
        margin-top: 200px;
    }
}

@media(max-width: 800px) {
    .workTitle {
        margin-top: 270px;
    }
}

@media(max-width: 730px) {
    .workTitle {
        margin-top: 290px;
    }
}

@media(min-width: 750px) {
    .workItem {
        display: grid;
        width: 90%;
        grid-gap: 15px;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    h2 {
        margin-top: -10px;
    }

    .buttonBox {
        margin-top: 20px;
    }

    .buttonBox2 {
        margin-top: -150px;
        margin-bottom: 20px;
    }

}

@media(max-width: 700px) {
    .frenchysFace {
        height: 100px;
        margin-top: 13%;
    }

    .frenchyTitle {
        margin-bottom: -20px;
    }

    .frenchySubTitle {
        margin-bottom: 25px;
    }

    .heroBox p {
        margin-top: 40px;
        font-size: 15px;
    }

    .workTitle {
        margin-top: 170px
    }

    .title2 {
        display: block;
    }

    .title1 {
        display: none
    }

    .workItem img{
        margin-bottom: 15px;
    }

    .readMoreButton {
        height: 35px;
    }

    #uxButton {
        margin-top: -300px
    }

    #img2 {
        display: flex;
    }

    #img1{
        display: none;
    }

    .heroBox2 {
        margin-bottom: -70px;
    }

}

@media(max-width: 665px) {
    .workTitle {
        margin-top: 205px;
    }
    a.lightbox img {
        width: 100vw
    }
}

@media(max-width: 600px) {
    .workTitle {
        margin-top: 280px;
    }
    a.lightbox img {
        width: 100vw
    }
}

@media(max-width: 500px) { 
    .frenchysFace {
        height: 90px;
        margin-top: 13%;
    }

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 20px
    }

    p {
        font-size: 12px;
        margin-left: 20px;
        margin-right: 20px
    }

    .heroBox p {
        font-size: 12px;
        margin-left: 20px;
        margin-right: 20px
    }

    .frenchyTitle {
        margin-bottom: -20px;
    }

    .frenchySubTitle {
        margin-bottom: 25px;
    }

    .workTitle {
        margin-top: 270px
    }

    .homeGif img{
        width: 100vw;
        margin-bottom: 100px;
    }

    .readMoreButton {
        width: 70%;
        height: 35px;
    }

    .frenchyTitle2 {
        bottom: 5em
    }

    .mailTo {
        height: 80px;
        margin: auto;
    }

    .emailMe {
        margin-bottom: -15px;
    }

    a.lightbox img {
        width: 100vw
    }

    .heroBox2 h1 {
        margin-top: -60px
    }

    .uxIntro {
        top: -170px
    }

    #uxButton {
        margin-top: -50px;
        margin-bottom: 120px;
    }

    .uxImg {
        width: 100vw
    }

}

@media(max-width: 420px) {
    .workTitle {
        margin-top: 300px
    }
}
