.navContainer {
    position: fixed;
    width: 100%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .5);
    z-index: 10;
}

nav {
    background: #3B3B3B;
    height: 70px;
}

.logo {
    position: relative;
    margin-top: 1%;
    left: 1%;
    height: 50px;
}

ul {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 10px;
    bottom: 65%;
    justify-self: flex-end;
    align-self: center;
    list-style-type: none;
    margin: 5px; 
    text-decoration: none;
}

li a {
    margin: 5px;
    color: white;
    text-decoration: none;
}

li a:visited {
    color: darkgray
}

li a:hover {
    color: #91AF69
 }